
<template>
  <div class=".gg-container-1">
    <div class="search-container">
      <div
          style="margin-left: auto; display: flex; justify-content: space-around"
      >
        <div
            class="search-container-fn-input"
            v-if="operateList.indexOf('add') > -1"
        >
          <el-button
              size="mini"
              type="primary"
              icon="el-icon-refresh"
              style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										  background-color: #2362FB; border-color: #2362FB"
              @click="handleEdit('add')"
          >新增
          </el-button>
        </div>
        <div
            class="search-container-fn-input"
            v-if="operateList.indexOf('add') > -1"
        >
          <el-button
              size="mini"
              type="primary"
              icon="el-icon-refresh"
              style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										  background-color: #2362FB; border-color: #2362FB"
              @click="handleEdit('edit', multipleSelectionPres[0])"
              v-if="multipleSelectionPres.length == 1"
          >编辑
          </el-button>
        </div>
        <div
            class="search-container-fn-input"
            v-if="operateList.indexOf('delete') > -1"
        >
          <el-button
              size="mini"
              type="primary"
              icon="el-icon-refresh"
              style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										  background-color: #2362FB; border-color: #2362FB"
              @click="handleDelete(multipleSelectionPres[0].id)"
              v-if="multipleSelectionPres.length == 1"
          >删除
          </el-button>
        </div>
        <div
            class="search-container-fn-input"
            v-if="operateList.indexOf('update_table') > -1"
        >
          <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit-outline"
              style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
											background-color: #2362FB; border-color: #2362FB"
              @click="handleEditTableHead"
          >编辑表头
          </el-button>
        </div>
        <div
            class="search-container-fn-input"
            v-if="operateList.indexOf('reset_query') > -1"
        >
          <el-button
              size="mini"
              type="primary"
              icon="el-icon-refresh"
              style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
											background-color: #2362FB; border-color: #2362FB"
              @click="handleResetSearch"
          >重置查找
          </el-button>
        </div>
        <!--对应第三方图标的button样式-->
      </div>
    </div>
    <!----------------表格---------------->
    <el-table
        border
        size="mini"
        v-loading="listLoading"
        :header-cell-style="{ 'text-align': 'center' }"
        :data="tableData"
        height="725"
        @selection-change="handleSelectionChange"
        style="width: 100%;z-index:0"
    >
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column type="index" align="center" width="50">
      </el-table-column>
      <template v-for="(column, index) in tableHead">
        <el-table-column
            :prop="column.column_prop"
            :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
            :width="column.width ? column.width : ''"
            :key="index"
            align="center"
            v-if="column.field_type === 'textBtn' && column.visible === true"
            show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a
                style="font-size: 12px; color: #2379fb"
                @click.prevent="editArticle('view', scope.row)"
            >
              {{ scope.row[column.column_prop] }}
            </a>
          </template>
        </el-table-column>
        <!-- <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="
            column.field_type === 'image' &&
              column.visible === true
          "
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-avatar :src="scope.row.head_portrait"></el-avatar>
          </template>
        </el-table-column> -->
        <el-table-column
            :prop="column.column_prop"
            :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
            :width="column.width ? column.width : ''"
            :key="index"
            align="center"
            v-else-if="column.field_type === 'select' && column.visible === true"
            show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-switch
                class="switch"
                @change="changeExamine(scope.row)"
                v-model="scope.row.status"
                :active-value="1"
                :inactive-value="0"
                active-text="启用"
                inactive-text="禁用"
                active-color="#13ce66"
                inactive-color="#C8C8C8"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
            :sortable="tableHeadSortable.includes(column.column_prop)"
            :prop="column.column_prop"
            :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
            :width="column.width ? column.width : ''"
            :key="index"
            align="center"
            v-else-if="column.visible === true"
            show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a>{{ scope.row[column.column_prop] }}</a>
          </template>
          <template #header>
            <el-popover
                placement="bottom"
                title=""
                min-width="160"
                trigger="click"
                v-if="
                searchTableHead.filter(
                  (item) => item.name == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                    column.column_label_user_definition
                        ? column.column_label_user_definition
                        : column.column_label
                  }}</span>
                <i
                    style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                    class="el-icon-search"
                    :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>
              <el-select
                  v-if="'source_name' == column.column_prop"
                  style="width: 200px"
                  @change="
                  handleSearch(column.column_prop, searchParams.source_name)
                "
                  v-model="searchParams.source"
                  clearable
                  placeholder="请选择"
                  size="small"
              >
                <el-option
                    v-for="(key, value) in source_name"
                    :key="key"
                    :label="key"
                    :value="value"
                >
                </el-option>
              </el-select>
              <el-input
                  v-if="'mobile' == column.column_prop"
                  size="mini"
                  placeholder=""
                  prefix-icon="el-icon-search"
                  v-model="searchParams.mobile"
                  clearable
                  @change="handleSearch(column.column_prop, searchParams.mobile)"
              >
              </el-input>
            </el-popover>
            <span v-else>{{
                column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
              }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>

    <!----------------分页---------------->
    <!--<pagination :total="total" :page.sync="listQuery.page" -->
    <!--						:limit.sync="listQuery.limit"-->
    <!--						@pagination="getList"/>-->

    <pagination
        :total="total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="getList"
    />
    <!--编辑表头-->
    <editTableHead
        :isActiveEditTableHead.sync="
        isActiveEditTableHead
      "
        @_getAdminFieldIndex="_getAdminFieldIndex"
        v-if="isActiveEditTableHead"
        @getList="getList"
        :table_type="table_type"
    ></editTableHead>
    <el-dialog
        :visible.sync="openLog"
        width="35%"
        :show-close="false"
    >
      <span slot="title" style="border-bottom:2px solid #1890FF">{{ type_log == 'add' ? '新增' : '编辑' }}</span>
      <div class="row alc" style="flex-wrap: wrap;justify-content: space-between">
        <div class="row alc" style="margin-bottom:20px;">
          <div style="width:70px;"><span style="color: red">*</span>标题</div>
          <div>
            <el-input
                size="mini"
                v-model="info.title"
                placeholder="请输入标题"
            ></el-input>
          </div>
        </div>
        <div class="row alc" style="margin-bottom:20px;">
          <div style="width:70px;"><span style="color: red">*</span>版本号</div>
          <div>
            <el-input
                size="mini"
                v-model="info.version"
                placeholder="请输入版本号"
            ></el-input>
          </div>
        </div>
        <div class="row alc" style="margin-bottom:20px;">
          <div style="width:70px;"><span style="color: red">*</span>类型</div>
          <div style="width:175px">
            <el-radio v-model="info.type" :label="1">商城</el-radio>
            <el-radio v-model="info.type" :label="2">其他</el-radio>
          </div>
        </div>
        <div class="row alc" style="margin-bottom:20px;">
          <div style="width:70px;"><span style="color: red">*</span>发布日期</div>
          <div>
            <el-date-picker
                v-model="info.publish_at"
                size="mini"
                value-format="yyyy-MM-dd"
                style="width:175px;"
                type="date"
                placeholder="选择日期">
            </el-date-picker>
          </div>
        </div>
        <div class="row" style="margin-bottom:20px;align-items: flex-start">
          <div style="width:70px;"><span style="color: red">*</span>发布内容</div>
          <div>
            <el-input
                type="textarea"
                style="width: 300px;"
                size="mini"
                v-model="info.content"
                placeholder="请输入发布内容"
            ></el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="openLog = false" size="mini">取 消</el-button>
    <el-button type="primary" @click="_ok" size="mini">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import editTableHead from "@/components/editTableHead/editTableHead";
import pagination from "@/components/Pagination";
import {delAppVersionDelete, getAppVersionList, postAppVersionAdd, putAppVersionEdit} from "@/newApi/system";
import {mapState} from "vuex";

export default {
  name: "versionsList",
  components: {
    editTableHead,
    pagination,
  },
  computed: {
    ...mapState({
      table_options: (state) => state.user.table_options,
    })
  },
  async created() {
    await this._getAdminFieldIndex();
    await this.getList();
  },
  mounted() {
    this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      this.operateList = res;
    });
  },
  data() {
    return {
      info: {
        title: '',
        content: '',
        publish_at: '',
        type: 1,
        version: '',
      },
      openLog: false,
      type_log: 'add',
      table_type: 'versionsList',
      operateList: [],
      isActiveEditTableHead: false,
      multipleSelectionPres: [],
      tableHead: [],
      searchTableHead: [
        //控制搜索
        {
          name: "mobile",
          isSearch: false,
        },
        {
          name: "source_name",
          isSearch: false,
        },
      ],
      tableHeadSortable: [
        //控制排序
        "created_at",
        "expired_at",
        "next_visit_at",
        "end_visit_at",
        "end_order_at",
        "publish_at"
      ],
      dialogVisible: false,
      listLoading: false,
      searchParams: {
        timeValue: [],
      },
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      total: 0,
      tableData: [],
    };
  },
  methods: {
    //删除
    handleDelete(id) {
      this.$confirm('此操作将永久删除该版本, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        delAppVersionDelete(id)
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.getList();
              }
            })
            .catch((error) => {
              console.log(error);
            });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

    //新增
    _postAppVersionAdd() {
      postAppVersionAdd(this.info)
          .then((res) => {
            if (res.code === 200) {
              this.$message({
                message: "新增成功",
                type: "success",
              });
              this.getList();
              this.openLog = false
            }
          })
          .catch((error) => {
            console.log(error);
          });
    },
    //编辑
    _putAppVersionEdit() {
      this.info.id = this.multipleSelectionPres[0].id
      putAppVersionEdit(this.info)
          .then((res) => {
            if (res.code === 200) {
              this.$message({
                message: "编辑成功",
                type: "success",
              });
              this.getList();
              this.openLog = false
            }
          })
          .catch((error) => {
            console.log(error);
          });
    },

    _ok() {
      if (!this.info.title) {
        this.$message({
          message: "请输入标题",
          type: "warning",
        });
        return
      }
      if (!this.info.version) {
        this.$message({
          message: "请输入版本号",
          type: "warning",
        });
        return
      }
      if (!this.info.publish_at) {
        this.$message({
          message: "请选择发布日期",
          type: "warning",
        });
        return
      }
      if (!this.info.content) {
        this.$message({
          message: "请输入发布内容",
          type: "warning",
        });
        return
      }
      console.log(this.info)
      if (this.type_log == 'add') {
        this._postAppVersionAdd()
      } else {
        this._putAppVersionEdit()
      }
    },
    handleEdit(type, row) {
      this.info = {}
      this.type_log = type
      console.log(type, row, 123123);
      if (type == 'edit') {
        this.info = {
          title: row.title,
          content: row.content,
          publish_at: row.publish_at,
          version: row.version,
          type: row.type_name == '其他' ? 2 : 1
        }
      }
      this.openLog = true
    },
    handleSelectionChange(val) {
      console.log(val);
      console.log(this.multipleSelection, 43435435);
      this.multipleSelectionPres = [];
      val.forEach((item) => {
        this.multipleSelectionPres.push(item);
      });
    },
    handleSearch(prop, value) {
      console.log(prop, value, "2312343");
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    handleResetSearch() {
      this.searchParams = {
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true;
    },
    async _getAdminFieldIndex() {
      console.log(this.table_options.versionsList);
      this.tableHead = this.table_options.versionsList;
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: "organizationList",
      //   };
      //   const res = await getAdminFieldIndex(params);
      //   this.tableHead = res.data;
      //   console.log(this.tableHead);
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },
    getList(rest) {
      if (rest === "restPage") {
        this.listQuery.page = 1;
      }
      this.listLoading = true;
      let params = {
        ...this.searchParams,
      };
      params.page = this.listQuery.page;
      params.limit = this.listQuery.limit;
      getAppVersionList(params)
          .then((response) => {
            let data = response;
            if (data.code === 200) {
              let _data = data.data;
              this.total = _data.total;
              this.listQuery.limit = Number(_data.per_page);
              this.tableData = _data.list;
            }
            this.listLoading = false;
          })
          .catch((error) => {
            console.log(error);
            this.listLoading = false;
          });
    },
  },
};
</script>

<style scoped lang="scss">
.row {
  display: flex;
}

.alc {
  align-items: center;
}

.gg-container {
  position: relative;
  //   height:100vh
}

.table-username {
  font-size: 14px;
  color: #2632fb;

  &:hover {
    cursor: pointer;
  }
}

/*drawer*/
/deep/ .el-drawer {
  bottom: 0 !important;
}

/deep/ .el-drawer__header {
  padding: 0;
  margin: 0;
}

/deep/ .el-drawer.rtl {
  overflow: visible;
  //   height: 91vh;
  bottom: 0;
  //   margin-top: 9vh;
  position: absolute;
}

.mouseOverAvatar {
  opacity: 0.5;
}

.avatars {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: block;
  box-sizing: border-box;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.mouseOver {
  position: absolute;
  width: 68px;
  height: 68px;
  top: -4px;
  left: -4px;
  opacity: 0.6;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 298px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatars {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: block;
  box-sizing: border-box;
}

.elUpload {
  text-align: center;
  position: relative;

  .avatar-uploader {
    border: 1px dotted #666;
  }

  .el-upload {
    width: 100%;

    .avatar-uploader-icon {
      width: 100%;
    }
  }

  .avatar {
    max-width: 100%;
  }

  .del {
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    font-size: 30px;
    color: red;
    cursor: pointer;
  }
}

.addExperience {
  border: 1px solid #f5f5f5;
  color: #a7a7a7;
  text-align: center;
}

.addExperience:hover {
  cursor: pointer;
}

.docPhoto {
  width: 160px;
}

.el-date-editor.el-input {
  width: auto;
}
</style>
