import request from '@/utils/request'
//获取版本信息
export function getAppVersionList(params) {
    return request({
        url: `/admin/version/list`,
        method: 'get',
        params
    })
}
//获取版本详情
export function getAppVersionDetail(id) {
    return request({
        url: `/admin/version/${id}`,
        method: 'get',
    })
}
//添加版本
export function postAppVersionAdd(data) {
    return request({
        url: `/admin/version/store`,
        method: 'post',
        data
    })
}
//修改版本
export function putAppVersionEdit(data) {
    return request({
        url: `/admin/version/edit/${data.id}`,
        method: 'post',
        data
    })
}
//删除版本
export function delAppVersionDelete(id) {
    return request({
        url: `/admin/version/${id}`,
        method: 'delete',
    })
}